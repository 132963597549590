import React from "react";
import { graphql } from "gatsby";
import Page from "../components/page";
import Section from "../components/section";
import Content, { HTMLContent } from "../components/Content";
import FullWidthImage from "../components/FullWidthImage";
import { Title } from "../components/text";

export const AboutPageTemplate = ({
  title,
  image,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <Page>
      <FullWidthImage image={image} height="50vh" parallax={50}>
        <Title bright>{title}</Title>
      </FullWidthImage>
      <Section>
        <PageContent className="content" content={content} />
      </Section>
    </Page>
  );
};

export default ({ data }) => {
  const { markdownRemark: post } = data;

  console.warn(post.html);

  return (
    <AboutPageTemplate
      contentComponent={HTMLContent}
      title={post.frontmatter.title}
      image={post.frontmatter.image}
      content={post.html}
    />
  );
};

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 64) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
